/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import Quiz from "./player/Quiz"

import NoSubBanner from "../../common/misc/nosubbanner"
import PageBanner from "../../common/misc/pagebanner"

import StarSVG from "../../../scss/img/icon/sibsvg/sib-icon-star.svg"
import CheckSVG from "../../../scss/img/icon/sibsvg/sib-icon-check-circle.svg"
import LockSVG from "../../../scss/img/icon/sibsvg/sib-icon-lock.svg"

const SIBQuiz = (props) => {
  const [selecting, setSelecting] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [selectedAct, setSelectedAct] = useState(0)
  const [preppedQuizData, setPreppedQuizData] = useState()
  const numQuestions = 10

  var isUnpaid = props.subInfo && props.subInfo.isUnpaid
  const unpaidText = `You are limited to a small sampling of questions from the entire play.
    Subscribe for full access to all quiz options!`

  const setCatSelected = (value) => {
    if (!props.subInfo || props.subInfo.isUnpaid) {
      return
    }
    setSelectedCategory(value)
  }

  const setActSelected = (e) => {
    e.preventDefault()
    setSelectedAct(e.target.value)
  }

  const startQuiz = (e) => {
    e.preventDefault()
    prepareQuiz()
    setSelecting(false)
  }

  const backToSelection = (e) => {
    e.preventDefault()
    setSelecting(true)
  }

  useEffect(() => {
    if (!props.subInfo || props.subInfo.isUnpaid) {
      setSelectedCategory("all")
    }
  })

  const getRandom = (arr, n) => {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len)
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available")
    while (n--) {
      var x = Math.floor(Math.random() * len)
      result[n] = arr[x in taken ? taken[x] : x]
      taken[x] = --len in taken ? taken[len] : len
    }
    return result
  }

  const prepareQuiz = () => {
    const retQuestions = getRandom(
      props.quizData.questions
        .filter((e) => {
          return (
            selectedCategory === "all" ||
            e.sibCategory.toLowerCase() === selectedCategory
          )
        })
        .filter((e) => {
          return selectedAct === 0 || e.actCategory === parseInt(selectedAct)
        })
        .filter((e) => {
          if (props.subInfo && props.subInfo.isUnpaid) {
            if (e.requiresPaidSub) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        }),
      /*.filter(e => {
          return e.questionType === "photo"
        }),*/
      numQuestions
    )
    setPreppedQuizData({
      quizTitle: "Shakespeare In Bits Quiz",
      quizSynopsis:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
      questions: retQuestions,
    })
  }

  const buttonDefs = [
    {
      label: "Vintage Vocab",
      value: "vv",
      blurb: "Demonstrate your mastery of Shakespearian words and phrases",
    },
    {
      label: "Quizzical Quotes",
      value: "qq",
      blurb: "Test your memory of memorable quotes from the play",
    },
    {
      label: "That is the Question",
      value: "titq",
      blurb:
        "How well do you know the play? Find out by answering general knowledge questions",
    },
    {
      label: "All Categories",
      value: "all",
      blurb: "Random set of questions drawn from all of our quiz categories",
    },
  ]

  return (
    <>
      <PageBanner title={`${props.playTitle} - Quizzes`} />
        <NoSubBanner isUnpaid={isUnpaid} text={unpaidText} />
      <div className="outer-keypage-container quiz-container container py-0 mt-0 mb-4">
        {selecting ? (
          <SIBQuizSelectBar
            selectedCategory={selectedCategory}
            setCatSelected={setCatSelected}
            setActSelected={setActSelected}
            buttonDefs={buttonDefs}
            startQuiz={startQuiz}
            isUnpaid={props.subInfo && props.subInfo.isUnpaid}
          />
        ) : (
          <div className="columns mt-1">
            <div className="column">
              <div className="title is-5 has-text-sibredorange mb-5">
                {`Your Quiz - `}
                {
                  buttonDefs.find((e) => {
                    return e.value === selectedCategory
                  }).label
                }{" "}
                - {selectedAct === 0 ? "Entire Play" : `Act ${selectedAct}`}
                {/*<div
                  aria-hidden="true"
                  className="title is-6 has-text-primary mt-5"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => backToSelection(e)}
                  onKeyDown={(e) => backToSelection(e)}
                >
                  Back to Quiz Selection
                </div>*/}
              </div>
              <Quiz
                quiz={preppedQuizData}
                continueTillCorrect={false}
                showInstantFeedback={true}
                quizImages={props.quizImages}
                backToSelection={backToSelection}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const SIBQuizSelectBar = (props) => {
  const buttonDefs = props.buttonDefs
  let colIndex = 0

  return (
    <>
      <div className="title is-5 has-text-sibblack mt-5 mb-5">
        Select a Quiz Category:
      </div>
      <div className="columns">
        {buttonDefs.map((buttonDef) => {
          return (
            <QuizSelectButtonBarButton
              key={`quiz-select-${colIndex++}`}
              isUnpaid={props.isUnpaid}
              colIndex={colIndex++}
              buttonDef={buttonDef}
              currentSection={props.section}
              selectedCategory={props.selectedCategory}
              setCatSelected={props.setCatSelected}
              setActSelected={props.setActSelected}
            />
          )
        })}
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="title is-5 mt-4 mb-4">Draw Questions From:</div>
          <div className="select is-sibgrey4base mb-2 is-fullwidth">
            <select
              onChange={(e) => props.setActSelected(e)}
              onBlur={(e) => props.setActSelected(e)}
              defaultValue={0}
              disabled={props.isUnpaid}
            >
              {[0, 1, 2, 3, 4, 5].map((actNum) => {
                return (
                  <option value={actNum} key={actNum}>
                    {actNum === 0 ? "Entire Play (All Acts)" : `Act ${actNum}`}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-4">
            <button
              className="button is-black is-fullwidth"
              rel="noopener noreferrer"
              id="btn-spaced"
              onClick={(e) => props.startQuiz(e)}
            >
              Start Quiz
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const QuizSelectButtonBarButton = (props) => {
  const isCurrentSelection = props.selectedCategory === props.buttonDef.value
  const isDisabled = props.isUnpaid && props.buttonDef.value !== "all"
  return (
    <div
      className={`column m-1 p-1
          ${
            isDisabled
              ? `quiz-button-disabled has-background-sibgrey3light`
              : `${isCurrentSelection ? "quiz-button-selected" : "quiz-button"}`
          } 
    `}
      style={{ display: "flex" }}
      onClick={(e) => props.setCatSelected(props.buttonDef.value)}
      onKeyDown={(e) => props.setCatSelected(props.buttonDef.value)}
      aria-hidden="true"
    >
      <div>
        <div
          className={`${isCurrentSelection || isDisabled ? "is-hidden" : ""}`}
        >
          <StarSVG />
        </div>
        <div
          className={`${isCurrentSelection && !isDisabled ? "" : "is-hidden"}`}
        >
          <CheckSVG fill={`#ff3a00`} />
        </div>
        <div className={`${isDisabled ? "" : "is-hidden"}`}>
          <LockSVG fill={`#909090`} />
        </div>
        <div className="title is-6 has-text-weight-bold my-1">
          {props.buttonDef.label}
        </div>
        <div className="is-size-7">{props.buttonDef.blurb}</div>
      </div>
    </div>
  )
}

export default SIBQuiz
