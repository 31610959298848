import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/common/layout/layout"
import PrivateContent from "../components/user/privatecontent"

import SIBQuiz from "../components/product/sibquiz/sibquiz"

const QuizContainer = props => {
  let qImageMappings = {}
  for (var edge of props.data.qImages.edges) {
    let node = edge.node
    var startIndex = node.relativePath.lastIndexOf("/") + 1
    let qLabel = node.relativePath.substring(
      startIndex,
      node.relativePath.length - 4
    )
    qImageMappings[qLabel] = node.childImageSharp
  }
  return (
    <Layout>
      <PrivateContent accessLevel={"requireslogin"}>
        <SIBQuiz
          playTitle={props.pageContext.playTitle}
          playId={props.pageContext
            .playId}
          quizData={props.pageContext
            .quizData}
          quizImages={qImageMappings}
        />
      </PrivateContent>
    </Layout>
  )
}

export const query = graphql`query QuizQuestionImages($quizQuestionImagesRegex: String) {
  qImages: allFile(filter: {relativePath: {regex: $quizQuestionImagesRegex}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          gatsbyImageData(width: 143, layout: FIXED)
        }
      }
    }
  }
}
`

export default QuizContainer
